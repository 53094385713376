import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

export const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: "#67b1a6",
      },
      secondary: {
        main: "#e1d836",
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    typography: {
      fontFamily: ["-apple-system", "Roboto", "sans-serif", "serif"].join(","),
    },
    breakpoints: {
      values: {
        mobile:640,
        tablet: 769,
        laptop: 1024,
        desktop: 1280,
      },
    },
  })
);

//   theme.typography.h3 = {
//     fontSize: '1.2rem',
//     '@media (min-width:600px)': {
//       fontSize: '1.5rem',
//     },
//     [theme.breakpoints.up('md')]: {
//       fontSize: '2.4rem',
//     },
//   };

// h1
// h2
// h3
// h4
// h5
// h6
// subtitle1
// subtitle2
// body1
// body2
// button
// caption
// overline

// const theme = createMuiTheme({
//     typography: {
//       subtitle1: {
//         fontSize: 12,
//       },
//       body1: {
//         fontWeight: 500,
//       },
//       button: {
//         fontStyle: 'italic',
//       },
//     },
//   });